import { VideoVisibility } from '@src/constants'

export enum BatchImporterMediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM'
}

export const mediaTypeIconMap = {
  VIDEO: '/icon/videocam.svg',
  IMAGE: '/icon/image.svg',
  CAROUSEL_ALBUM: '/icon/album.svg'
}

export enum ConsentStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Denied = 'Denied'
}

export type BatchImporterMedia = {
  id?: string
  caption?: string
  avatar?: string
  username?: string
  mediaType?: BatchImporterMediaType
  thumbnailUrl?: string
  mediaUrl?: string
  likeCount?: number
  timestamp?: string
  hashtags?: string[]
  children?: BatchImporterMedia[]

  signature?: {
    key?: string
    url?: string
  }
  products?: {
    business_store_id?: string
    product_id: string
  }[]
  needConsent?: boolean
  consentStatus?: ConsentStatus
}

export type BatchImporterEditMedia = {
  editCaption?: string
  editHashtags?: string[]
  playlists?: string[]
  visibility?: VideoVisibility
  agreeTerms?: boolean
  products?: {
    business_store_id?: string
    product_id: string
  }[]
  productOptions?: {
    id: string
    unitId?: string
    businessStoreId?: string
  }[]
} & BatchImporterMedia

export type BatchImporterResult = {
  success: boolean
  error?: string
}

export enum ImporterSource {
  BULK_UPLOAD = 'bulk_upload',
  INSTAGRAM_PERSONAL = 'instagram_personal',
  INSTAGRAM_BUSINESS = 'instagram_business',
  INSTAGRAM_LINK = 'instagram_link',
  TIKTOK_LINK = 'tiktok_link'
}

export enum ImporterSourceDetail {
  Hashtag = 'Hashtag',
  Username = 'Username',
  Account = 'Account'
}

export type TrackingDetails = {
  source: ImporterSource
  creationDetails: {
    source: 'social_media' | 'cms'
    detail: 'short_video' | 'instagram' | 'tiktok'
    creator_name: string
    app: 'web'
  }
}
